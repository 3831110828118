<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A buffer is prepared by mixing
        <stemble-latex content="$184\,\text{mL}$" />
        of
        <stemble-latex content="$0.442\,\text{M}\>$" />
        <chemical-latex content="HCl" />
        and
        <stemble-latex content="$0.500\,\text{L}$" />
        of
        <stemble-latex content="$0.400\,\text{M}\>$" />
        <chemical-latex content="CH3COONa" />
        . Calculate the
        <stemble-latex content="$\text{pH}$" />
        of the buffer solution.
      </p>

      <p class="mb-2">
        (
        <stemble-latex content="$\text{K}_\text{a}$" />
        of
        <chemical-latex content="CH3COOH" />
        =
        <stemble-latex content="$1.8\,x\,10^{-5}$" />
        )
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A6Q2',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
